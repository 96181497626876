import { Injectable } from '@angular/core';
import { TranslateService  } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  public activeLang = 'en';
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang(this.activeLang);
  }
  public changeLang(lang) {
    this.activeLang = lang;
    this.translate.use(lang);
  }
  public translation(key: string){
    let translate;
     this.translate.get(key).subscribe((result) =>   translate = result);
    return translate;
  }
}
