import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../pages/home/home.component.module').then(m => m.HomePageModule),
  },
  {
    path: 'selection-enterprise/:lang',
    // eslint-disable-next-line max-len
    loadChildren: () => import('@pages/selection-enterprise/selection-enterprise.component.module').then(m => m.SelectionEnterpriseComponentModule),
  },
  {
    path: 'selection-package/:enterprise/:lang',
    loadChildren: () => import('@pages/selection-package/selection-package.component.module').then(m => m.SelectionPackageComponentModule),
  },
  {
    path: 'package/:enterprise/:package/:lang',
    loadChildren: () => import('@pages/package/package.component.module').then(m => m.PackageComponentModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
