import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  public activeLang = 'en';
  isMobile = {
    android: () => navigator.userAgent.match(/Android/i),
    blackBerry: () => navigator.userAgent.match(/BlackBerry/i),
    iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
    opera: () => navigator.userAgent.match(/Opera Mini/i),
    windows: () => navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i),
    anyDevice() {
      return (this.android() || this.blackBerry() || this.iOS() || this.opera() || this.windows());
    }
  };
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang(this.activeLang);
    if(!this.isMobile.anyDevice()){
      location.href='https://www.playamia.com/';
    }
  }
  ngOnInit() {
    this.translate.setDefaultLang(this.activeLang);
  }

}

